@import 'base';
@import 'assets/css/fa-icons.css';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/@angular/material/theming';
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import '../node_modules/@circlon/angular-tree-component/css/angular-tree-component.css';
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@angular/cdk/overlay-prebuilt.css';

@include mat-core();

$mat-blue: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: $principal-blue,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);


$mat-pink: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: $principal-lightblue,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$candy-app-primary: mat-palette($mat-blue);
$candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

input[type="checkbox"] {
  accent-color: #289CCD;
  appearance: none;
  color: #289CCD;
  --tw-ring-offset-width: 0px !important;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
}
input[type="checkbox"]:checked {
  background-color: #289CCD;
}

.text_bold{
  font-weight: bold;
}

.mat-menu-content{
  padding: 0 !important;
}

.items-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.items-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-comments {
  position: relative;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.3));
  .load_box {
    position: relative;
    min-height: 100px;
    height: 100px;
    @include box-general;
    margin-bottom: 2%;
    .empty-flag{
      position: relative;
      display: flex;
      background-color: $principal-gray-light;
      width: 50px;
      height: 50px;
      border-radius: 100px;
    }

    .empty-comment{
      position: relative;
      width: 70%;
      height: 10%;
      background-color: $principal-gray-light;
      border-radius: 30px;
      margin: 1% 0;
      color: $principal-gray-light;
      font-size: 0;
    }

  }
}

.hotel-finder {

  .mat-form-field-ripple {
    background-color: $principal-lightblue !important;
  }

  .mat-form-field-label {
    color: $principal-lightblue !important;
  }

  .mat-form-field-underline {
    background-color: $principal-lightblue !important;
  }

  input {
    color: $blue_mh;
  }

  button {
    color: $principal-lightblue !important;
  }
}

.modal-styles {
  .modal-content {
    border-radius: 50px;
    padding: 50px;
  }
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

.overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-backdrop-notification {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999 !important;
}

.overlay-backdrop-inherit {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: inherit;
}

.overlay-panel {
  width: 550px;
  height: 350px;
  max-width: 95%;
  max-height: 95%;

  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  overflow: hidden;

  &.mobile {
    width: calc(94% - 20px)
  }

  &.sm {
    height: 250px;
  }

  &.md {
    width: 600px;
    height: 500px;
  }

  &.lg {
    width: 800px;
    height: 500px;
  }

  &.xl {
    width: 1000px;
    height: 800px;
  }

  &.no-padding {
    padding: 0;
  }

  &.xlg {
    width: 1000px;
    height: 700px;
  }

  &.xxlg {
    width: 1200px;
    height: 970px;
  }

  &.xxxlg {
    width: 1440px;
    height: 1100px;
  }


  &.fit-height-content {
    height: fit-content;
  }

  &.no-max-height {
    max-height: none;
  }

  &.scroll-y {
    overflow-y: scroll;
  }
  &.scroll-x {
    overflow-x: scroll;
  }
  &.notification {
    z-index: 999999 !important;
  }
}

.overlay-centered {
  width: calc(100vw - 50%);
}

.tooltip-container {
  z-index: 9999999999999;
  position: fixed;
  padding: 12px 14px;
  line-height: initial;
  color: $white;
  background: $tooltip-background;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 13px;
  text-align: left;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
  }

  &-right {
    transform:translateY(-50%);
    margin-left: 7px;

    &::before {
      border: 5px solid $tooltip-background;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left: none;
      left: -5px;
      top: calc(50% - 5px);
    }
  }

  &-left {
    transform:translate(calc(-100% - 7px), -50%);

    &::before {
      border: 5px solid $tooltip-background;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right: none;
      right: -5px;
      top: calc(50% - 5px);
    }
  }

  &-below {
    transform:translateX(-50%);
    margin-top: 7px;

    &::before {
      border: 5px solid $tooltip-background;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top: none;
      left: calc(50% - 5px);
      top: -5px;
    }
  }

  &-above {
    transform:translate(-50%, -100%);
    margin-bottom: 7px;

    &::before {
      border: 5px solid $tooltip-background;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom: none;
      left: calc(50% - 5px);
      bottom: -5px;
    }
  }
}

.snackbar-panel-success {
  background-color: $success-snackbar-bg-success;
  color: $success-snackbar-text-success;
}

.snackbar-panel-success-user-config {
  @apply tw-bg-notification-bg-success tw-text-white tw-text-xl tw-font-bold tw-flex tw-justify-center;
}

.snackbar-panel-error-user-config {
  @apply tw-bg-notification-bg-error tw-text-white tw-text-xl tw-font-bold tw-flex tw-justify-center;
}

.mat-simple-snackbar {
  font-size: 16px;
}

.snackbar-panel-error {
  background-color: $success-snackbar-bg-error;
  color: $success-snackbar-text-error;
}
.max-index-notification-wrapper {
  z-index: 999999 !important;
}
.noti-toggle-button {
  .mat-button-toggle-button {
    .mat-button-toggle-label-content {
      line-height: 35px !important;
    }
  }
}
