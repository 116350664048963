@import '../base';


#feature-survey, .modal-survey{
  .action-alternatives{
    font-weight: 400;
    margin: 10px 0;
  }

  .container{
    &.full-height{
      height: 100%;
    }
  }

  .title-question{
    padding: 5px 15px;
  }
  .alternatives-wrapp{
    padding: 0 15px;

    .option-wrapp{
      margin: 3px;
      border: 1px solid transparent;
      padding: 0px;
      border-radius: 20px;
      display: block;
      @include transition(all, 0.2s);
      &.radio{
        .mat-radio-label{
          display: block;
          padding: 5px;
        }
        .mat-radio-container{
          vertical-align: middle;
        }
      }

      &.checkbox{
        label{
          text-transform: none;
        }
        .mat-checkbox-inner-container{
          margin-left: 15px;
        }
        .mat-checkbox-label{
          font-size: 14px;
        }
      }

      &:hover, &.mat-radio-checked{
        border-color: $sruvey-principal-green;
        background-color: lighten($principal-gray-light,8%);
        cursor: pointer;
        @include transition(all, 0.2s);

        .mat-radio-container{
          .mat-radio-outer-circle{
            border-color: $sruvey-principal-green;
          }
        }

      }
      &.mat-radio-checked{
        $green: darken($sruvey-principal-green, 5%);
        .mat-radio-container{
          .mat-radio-outer-circle{
            border-color: $green;
          }
          .mat-radio-inner-circle{
            background-color: $green;
          }
        }
      }
      &.mat-checkbox-checked{

        .mat-checkbox-background{
          background-color: $sruvey-principal-green;
        }
      }
    }

  }
  .comment-wrapp{
    padding: 15px;
  }

  .field-form{
    mh-form-alerts{
      .alert_form{
        margin-top: 5px;
      }
    }
  }

  .required-sign{
    color: $principal-red;
  }


  .btn-confirm{
    border: 2px solid $sruvey-principal-green;
    padding: 2px 20px;
    min-width: 100px;
    color: $sruvey-principal-green;
    @include transition(all,0.2s);
    &:hover{
      background-color: $sruvey-principal-green;
      color: white;
      @include transition(all,0.2s);
    }
    &:disabled{
      border-color: $principal-gray-dark;
      color: $principal-gray-dark;
      &:hover{
        opacity: 1;
        background-color: white;
        border-color: $principal-gray-dark;
        color: $principal-gray-dark;
      }
    }
  }

  .btn-primary{
    border: none;
    min-width: 100px;
    border-radius: 100px;
    font-weight: 500;
    background-color: $sruvey-principal-green;
  }

  .total-height{
    height: 100%;
    padding-bottom: $survey-footer;
  }

  .error-message{
    color: $principal-red;
    font-size: 12px;
  }
}
