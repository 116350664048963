@import 'base';
@import 'fonts';
@import 'tables';
@import 'buttons';
@import 'form';
@import 'modals';

body, #body{
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-color: $body-brackground;
}

body#body{
  &.loading{
    background-color: transparent;
  }

}

.responsive{
  width: 100%;
}
mh-followup-dashboard,
mh-summary-survey,
mh-tripadvisor,
mh-nps,
mh-trend{
  width: 100%;
}

a{
  &:focus{
    outline: none;
  }
}

.box{
  @include box-general();
}
.shadow{
  @include box-general-shadow();
}


.filter-table{
  .mat-form-field{
    width: 100%;
  }
}

.invisible{
  visibility: hidden;
  position: relative;
}

@include font-elements('Yantramanav');

header{
  position: fixed;
  background: white;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  height: $header-height;
}
a, .link{
  @include link();
}

label{
  font-weight: bold;
  text-transform: uppercase;
  color: $font-gray-dark;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  vertical-align: middle;

}

#main_view::-webkit-scrollbar {
  display: none;
}
#main_view{
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 1;

  .close_sidebar{
    position: absolute;
    top: 0;
    right: 0;
  }

  .side_menu {
    position: fixed;
    left: 0;
    top: $header-height;
    background: $nav-principal-color;
    max-height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    &.expanded {
      width: $nav-side-width;
    }

    &.collapsed {
      width: $nav-side-width-collapsed;
    }
  }

  .mat-sidenav-content, mat-sidenav{
    display: flex;
    overflow: visible;
  }
  .main_content{
    padding: 10px;
    width: 100%;
    position: relative;
    .fade_loading{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0.8;
      z-index: 10;
      display: none;
      @include transition(all 0.3s);
      &.show{
        display: block;
        @include transition(all 0.3s);
      }
    }
    &.menu{
      padding-top: $header-height-menu +20;
    }

    &::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
      border-radius: 0px;
      background-color: transparent;
    }
  }
}
.align-center{ text-align: center}
.align-left{ text-align: left}
.align-right{ text-align: right}
.alig-middle{ vertical-align: middle;}




.shadow{
  box-shadow: 0 0 5px $principal_gray_light;
}
.ellipsis{
  width: 100%;
  @include no-wrap;
}

.preload{
  position: absolute;
  left: -10000px;
  top: -10000000px;
  visibility: hidden;
}

.scroll_area_x{
  max-width: 100%;
  overflow-x: scroll;
}
.after-inline:after,
.before-inline:before{
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-left: -4px;
}
.inline{
  vertical-align: middle;
  display: inline-block;
}

.grayscale{
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.format_date{
  span{
    font-weight: 400;
    color: $font-gray-dark;
  }
  i{
    margin-right: 2px;
    font-size: 10px;
  }
}
.bg_white{
  background-color: white;
}






.tooltip-inner{
  max-width: 300px;
}
ul{
  &.slim{
    padding: 3px 0px;
    padding-left: 25px;
    margin: 0;
    list-style-type: disc;
    li{
      text-align: left;
    }
  }
}
.normal_case{
  text-transform: none;
}
.capitalize{
  text-transform: capitalize;
}

ul.list_inline{

  padding: 0;
  margin: 0;
  li{
    display: inline-block;
  }

}



.hide_lg{
  @media (max-width: $media_lg){
    display: none!important;
  }
}

.hide_md{
  @media (max-width: $media_md){
    display: none!important;
  }
}

.hide_sm{
  @media (max-width: $media_sm){
    display: none!important;
  }
}

.hide_xs{
  @media (max-width: $media_sm){
    display: none!important;
  }
}

.wait_central{
  text-align: center;
  padding: 20px 0;
}
.full_height, .full-height{
  height: 100%;
}
.full_width,.full-width{
  width: 100%;
}
.full_size{
  height: 100%;
  width: 100%;
}


.mat-progress-spinner circle,
.mat-spinner circle{
  stroke: $principal-lightblue;
}

.mat-progress-bar{
  color: red;
  .mat-progress-bar-background,
  .mat-progress-bar-buffer{
    fill:             lighten($principal-lightblue,15%);
    background-color: lighten($principal-lightblue,15%);
  }
  .mat-progress-bar-fill::after{
    background-color: $principal-blue;
  }
}
input,.mat-input-element{
  &.find_input{
    padding: 5px 0;
    border-bottom: 1px solid $font-gray;
    border-radius: 0;
    &:focus,&:active{
      box-shadow: none;
      outline: none;
      border-color: $principal-lightblue;
      background-color: transparent;
    }
  }
}

.dropdown-toggle{
  border-radius: 100px;
  padding: 3px 6px;
  height: auto;
  line-height: 10px;
  &:after{
    content: "\f078";
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    border: none;
    width: auto;
    height: auto;
  }
}
.pointer{
  cursor: pointer;
}

.title_filter{
  padding: 0px;
  text-align: center;
  background-color: transparent;
  margin-bottom: 16px;
  overflow: hidden;
  height: 34px;
  @include transition(all 0.2s);


  &.show{
    height: auto;
    @include transition(all 0.2s);
  }


  .content_filters{
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 3px $principal-gray-light;
    margin: 1px 15px;
    border-radius: 0 0 7px 7px;
  }

  .title{
    text-align: left;
    color: white;
    margin: 0;
    background-color: $principal-blue;
    font-size: 18px;
    text-transform: uppercase;
    padding: 5px;
    cursor: pointer;
    border-radius: 30px;
    i,span{
      vertical-align: middle;
    }
  }
}

button{
  &:focus{
    outline: 5px auto $principal-gray-light;
  }
}

.upload-button{
  max-width: 180px;
  .custom-file-label{
    text-align: center;
    line-height: 1;
    text-transform: none;
    cursor: pointer;
    @include button_classic($font-black,15px);
    padding: 10px 5px;
    &:after{
      display: none
    }
  }
}



.cdk-global-overlay-wrapper, .cdk-overlay-container {
   z-index: 99999;
}
.cdk-overlay-connected-position-bounding-box {
  z-index: 99999;
}

.waiting_center{
  text-align: center;
  padding: 20px 0;
}


.mat-tab-group{
  .mat-tab-header{
    background-color: $principal-gray-light;
  }
}

.description-tooltip{
  font-size: 10px;
  margin-left: 3px;
  margin-bottom: 2px;
}

.not-paid{
  position: relative;
  max-height: 200px;
  overflow: hidden;
}
.fade-not-paid,.fade_not_paid{
  @include not_paid();
}

.drag-area{
  &.cdk-drop-list-dragging{
    .draggable{
      .icon-dragabble{
        cursor: grabbing;
      }
    }
    .cdk-drag{
      opacity: 0.7;
    }
  }
  .draggable{
    .icon-dragabble, .icon-dragabble:hover{
      cursor: grab;
    }
  }
}

.mat-accordion{
  .mat-expansion-panel{
    border: 1px solid transparent;
    @include box-general-shadow();
  }

  &.invalid{
    .mat-expansion-panel{
      border-color: rgba($principal-red, .4);
      @include box-general-shadow($principal-red);
    }
  }
}

.invalid{
  border-color: rgba($principal-red, .4);
  @include box-general-shadow($principal-red);
}
.cdk-drag-placeholder{
  cursor: grabbing!important;
  ::ng-deep{
    .sorting{
      cursor: grabbing!important;
    }
  }
}

.tooltip-icon{
  color: $font-gray;
  font-size: 10px;
  padding: 0 5px;
  vertical-align: middle;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}