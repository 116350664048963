.modal-content{
  .close{
    cursor: pointer;
    &:focus,&:active{
      outline: none;
    }
  }
  .close-cirlce{
    background-color: $font-gray-dark;
    color: white;
    font-size: 20px;
    padding: 2px 6px;
    border-radius: 100px;
    cursor: pointer;
  }
}
.modal-xl{
  .modal-dialog{
    max-width: 95%;
  }
  @media (max-width: $media_sm){
    .modal-dialog{
      max-width: 100%;
    }
  }
}

.modal-medium{
  max-width: 600px;
}

.modal-lg{
  max-width: 90%; //creo que este le falta el .modal-dialog

  @media (max-width: $media_lg){
    max-width: 90%
  }

  @media (max-width: $media_md){
    max-width: 90%
  }

}

.modal-md {
  .modal-dialog {
    max-width: 60%;

    @media (max-width: $media_lg){
      max-width: 70%;
    }

    @media (max-width: $media_md){
      max-width: 90%;
      margin: auto;
    }
  }
}

.modal{
  @media (max-width: $media_md){
    max-width: none;
  }
  @media (max-width: $media_lg){
    max-width: none;
  }

  &.modal-xl {
    @media (min-width: 1550px){
      max-width: none;
    }
  }
}

.modal_lg{



  .modal-dialog{
    max-width: 70%;
  }
  @media (max-width: $media_sm){
    .modal-dialog{
      max-width: 100%;
    }
  }
}

.modal,.modal-confirm{
  text-align: center;
  &.left{
    text-align: left;
  }
  .modal-header{
    background-color: $principal-lightblue;
    color: white;
    font-size: 20px;
    display: block;
    position: relative;
    .title{
      text-align: left;
      margin: 0 auto;
      font-weight: 400;
    }
    .close{
      position: absolute;
      right: 10px;
      top: 10px;
      color: white;
      cursor: pointer;
    }
  }

  .modal-content{
    background-color: white;
    text-align: left;
  }
  .modal-body{
    background-color: white;
  }

  .dialog{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    small{
      display: block;
      color: $font-gray;
      font-size: 14px;
    }
  }
  .fade_waiting{
    text-align: center;
  }
  .btn{
    margin-top: 15px;
    cursor: pointer;
    min-width: 100%;
    &-send{
      max-width: 200px;
      min-width: auto;
    }
  }
  &.danger{
    .modal-header{
      background-color: $principal-red;
    }
  }
  .mat-table{
    width: 100%;
  }
  &.modal-md{
    .modal-sm{
      max-width: 500px;
    }
  }
}

.modal-tables,
.modal-general{
  .title{
    font-size: 24px;
    font-weight: 300;
    background-color: $principal-lightblue;
    color: white;
    padding: 10px;
    margin: 0;
    span{
      font-weight: bold;
    }
  }
  a.email{
    color: $font-gray-dark;
  }
  thead{
    background-color: $principal-gray-dark;
    color: white;
    text-transform: capitalize;
    th{
      color: white;
    }
  }
  tbody{
    color: $font-gray-dark;
    td{
      font-size: 16px;
    }
  }
}

.modal-edit{
  .modal-header{
    font-size: 24px;
    font-weight: 300;
    background-color: $principal-gray-dark;
    color: white;
    padding: 10px;
    margin: 0;
    border-radius: 0;
    text-transform: uppercase;
    .close{
      color: white;
      cursor: pointer;
    }
  }
  .modal-body{
    min-height: 150px;
    background-color: white;
  }
  .fade_waiting{
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(white,0.8);
    top: 0;
    left: 0;
    z-index: 2;
    padding-top: 50px;
  }
  .bottom{
    margin-top: 15px;
    text-align: center;
    &.waiting{
      visibility: hidden;
    }
  }
}

.email-pms-modal{
  .summary{
    text-align: center;
  }
  .legend{
    color: $font-black;
    font-size: 20px;
    font-weight: 500;
    padding: 20px 0;
    span{
      display: block;
      font-weight: bold;
    }
  }
  .email_valids{
    color: white;
    font-weight: bold;
    font-size: 25px;
    &.bueno{
      background-color: $principal-yellow;
    }
    .formula{
      font-size: 16px;
      font-weight: normal;
    }
  }
}

.general_modal{
  .modal-header{
    background-color: $principal-gray-dark;
    border-radius: 0;
  }
  .modal-title{
    color: white;
    text-transform: uppercase;
  }
  .close{
    color: white;
    cursor: pointer;
  }
}


.modal-alert{
  .modal-header{
    .title{
      text-align: center;
    }
  }
  .modal-body{
    text-align: center;
    padding: 35px 5px;
    font-size: 18px;
  }
  .modal-small{
    padding: 0 20px;
    text-align: center;
    margin-bottom: 30px;
    color: $font-gray;
  }
  &.modal-alert{
    &-success{
      .modal-header{
        background-color: $principal-green;
      }
    }
    &-error{
      .modal-header{
        background-color: $principal-red;
      }
    }
  }
}


.modal-survey{
  .modal-header{
    border: 0;
    border-radius: 0;
    text-align: center;
    font-size: 16px;
    .close{
      font-size: 14px;
    }
  }
  .modal-content{
    background-color: transparent;
    border: 0;
  }
  &.show{
    .modal-content{
      background-color: white;
      border: 1px;
    }
  }
}

.modal-rounded .modal-dialog .modal-content {
  border-radius: 40px;
}
