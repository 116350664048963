.sticky-cell-common{
  &:first-child{
    position: sticky;
    left: 0;
    height: 100%;
    border-right: 1px solid $table-header;
    z-index: 1;
  }
}
.table-mh{
  &.sticky{
    .mat-footer-row,.mat-header-row{
      position: sticky;
      z-index: 2;
      .mat-header-cell,.mat-footer-cell{
        @extend .sticky-cell-common;
        background-color: $table-header;

      }
    }
    .mat-row{
      .mat-cell{
        @extend .sticky-cell-common;
        background-color: white;

      }
    }
    .mat-header-row{
      top: 0;
    }
    .mat-footer-row{
      bottom: 0;
    }
  }

  .mat-column-label{
    text-align: left;
  }

  .enphasis{
    font-weight: bold;
  }

  &.capitalize-header{
    mat-header-cell, .mat-sort-header-button{
      text-transform: capitalize;
    }
  }

  &.centered-header{
    mat-header-cell{
      justify-content: center;
      &:first-child{
        justify-content: left;
      }
    }
  }
  &.centered-content{
    mat-cell{
      justify-content: center;
      &:first-child{
        justify-content: left;
      }
    }
  }

  mat-header-row, mat-footer-row{
    background-color: $table-header;
    min-height: 39px;
  }
  .mat-row{
    border-left: 1px solid $table-header;
    border-right: 1px solid $table-header;
    &:hover{
      background-color: lighten($table-header,5%);
    }
  }

  mat-footer-row{
    text-align: center;
    .mat-footer-cell{
      &:first-child{
        text-align: left;
      }
    }
  }

  mat-header-cell, mat-cell, mat-footer-cell{
    font-size: 13px;
    color: $font-gray-dark;
    &.email{
      min-width: 200px;
    }
    &:first-of-type{
      padding-left: 10px;
    }
    .ota{
      text-transform: none;
    }
  }




  .format_date{
    font-size: 13px;
    line-height: 13px;
    .hour{
      font-size: 11px;
      i{
        font-size: 8px;
      }
    }
  }

  $center-keys: "room","orders_quantity";
  @each $item in $center-keys {
    mat-header-cell{
      &.#{$item}{
        justify-content: center;
      }
    }

    mat-cell{
      &.#{$item}{
        justify-content: center;
        padding-right: 15px;
      }
    }
  }
}




.table-general, .table-white{
  $sm_mat_row:36px;
  $mat_row: 55px;
  @include font('Yantramanav');

  .mat-cell, .mat-header-cell{
    padding: 0 5px;
    &.mat-column-email, &.mat-column-name{
      min-width: 200px;
    }
  }

  &.sticky{
    .mat-header-row, .mat-footer-row{
      position: sticky;
      z-index: 10;
    }
    .mat-header-row{
      top: 0;
    }
    .mat-footer-row{
      bottom: 0;
    }
  }

  &.fix_first_column{
    .fixed-cell,
    mat-header-cell:first-child{
      position: sticky;
      left: 0;
      z-index: 1;
      height: $mat_row;
      background: white;
      &.mat-footer-cell{
        background: $principal-gray-light;
      }
      &:after{
        display: inline-block;
        vertical-align: middle;
        content: '';
        height: 100%;
      }
    }
    .mat-header-cell{
      &:first-child{
        background-color: $table-header;
        padding-left: 10px;
        border-radius: 7px 0 0 0;
      }
    }
    .mat-header-row{
      padding-left: 0;
    }
    .mat-row, .mat-footer-row{
      padding-left: 0;
      .mat-footer-cell{
        min-height: 74px;
        &:first-child{
          padding-left: 9px;
        }
        &:nth-child(2){
          border-color: transparent;
        }
      }
      .mat-cell{
        min-height: $mat_row;
      }
      .mat-cell,.mat-footer-cell{
        justify-content: left;
        &:first-child{
          border-right: 1px solid $table-header;
        }
      }
    }

    mat-header-cell,
    .mat-header-cell,
    .mat-footer-cell,
    .mat-row .mat-cell{
      &:first-child{
        min-width: 230px;
        padding-left: 10px;
      }
    }

    .cell_data{
      width: 100%;
      display: inline-block;
      vertical-align: middle;
    }


  }

  &.fix_last_row{
    .fixed-row{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  &.clickeable{
    tbody tr, mat-row{
      cursor: pointer;
    }
  }

  &.sm{
    mat-header-row{
      min-height: 32px;
    }
    .mat-row{
      min-height: $sm_mat_row;
    }
    .mat-sort-header-container{
      min-width: 90px;
    }
    &.fix_last_row{
      padding-bottom: $sm_mat_row;
      .fixed-row{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
  &.scores{
    mat-header-row mat-header-cell, mat-row mat-cell{
      min-width: 200px;
      @include font('Yantramanav');
    }

  }
  &.center_info{
    mat-header-cell{
      justify-content: center;
      &:first-child{
        .mat-sort-header-container{
          text-align: left;
        }

      }

    }
    mat-row{
      justify-content: center;
      .mat-cell, mat-cell{
        justify-content: center;
        text-align: center;
        &:first-child{
          text-align: left;
          justify-content: left;
        }
      }
      &.highlight{
        border-color: $principal-purple;
      }
    }
    .mat-sort-header-container{
      &:after{
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
      }

    }
    .mat-sort-header-sorted{
      .mat-sort-header-container:after{
        content: none;
      }
    }
    .mat-sort-header-button{
      padding-left: 12px;
      padding-right: 4px;
    }
    .mat-sort-header-button,
    .mat-sort-header-arrow{
      margin: 0 auto;
      &:active, &:focus{
        outline: none;
      }
    }
    .mat-footer-row{
      margin-bottom: 0;
      .mat-footer-cell{
        justify-content: center;
        text-align: center;
        &:first-child{
          text-align: left;
          justify-content: left;
        }
      }
    }
  }

  .label-left{
    width: 300px;
    @include no-wrap;
  }
  .mat-footer-row{
    margin-bottom: 0;
    background-color: $principal-gray-light;
  }
}
.table-general{
  width: 100%;
  margin: 1px 0;
  &.mat-table{
    background: transparent;
  }
  &.align-middle{
    td{
      vertical-align: middle;
    }
  }
  thead, .mat-header-row{
    background-color: $table-header;
    border-radius: 7px 7px 0px 0;
    th, .mat-header-cell{
      color: $table-color;
      font-size: 17px;
      font-weight: 500;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      &.highlight{
        background-color: $principal-lightblue;
        color: white;
        .mat-sort-header-stem,
        .mat-sort-header-arrow{
          color: white;
        }
      }
    }
  }
  tbody, &.mat-table{
    th, .mat-cell{
      color: $font-gray-dark;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }
    tr{
      padding: 5px;
    }
    tr, .mat-row{
      background-color: white;
      border-bottom: 1px solid darken($principal-gray-light, 5%);

      &:hover{
        background-color: darken($principal-gray-light, 5%);
        .mat-cell{
          background-color: darken($principal-gray-light, 5%);
        }
      }
      &.fixed-row{
        background-color: $table-bottom;
        .mat-cell{
          background-color: $table-bottom;
        }
      }
    }

  }
  .mat-footer-row{
    margin-bottom: 15px;
    .mat-footer-cell{
      font-weight: bold;
      font-size: 16px;
      border-left: 1px solid darken($principal-gray-light,5%);
    }
  }
}

.table-white{
  thead, mat-header-row{
    th, mat-header-cell{
      font-size: 16px;
      font-weight: bold;
      text-transform: capitalize;
      button{
        text-transform: capitalize;
      }
    }
  }
  .mat-row{
    border-color: transparent;
  }
  .mat-header-cell, .mat-cell, .mat-footer-cell{
    border-left: 1px solid darken($principal-gray-light,5%);
    &:first-child{
      border-color: transparent;
    }
  }
}
.container_table{
  overflow: hidden;

  &.waiting{
    visibility: hidden;
  }

}
