$header-height: 65px;
$header-height-menu: 30px;

$body-brackground: #f9fafc;

$grid-columns:      12;

$sm: 820px;
$md: 980px;
$lg: 1290px;
$xl: 1550px;

$screen_lg: $xl - 1;
$screen_md: $lg - 1;
$screen_sm: $md - 1;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: $sm,
  // Medium screen / tablet
  md: $md,
  // Large screen / desktop
  lg: $lg,
  // Extra large screen / wide desktop
  xl: $xl
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$media_lg:            $xl - 1;
$media_md:            $lg - 1;
$media_sm:            $md - 1;
$media_xs:            $sm - 1;




$font-black: #333;
$font-gray: #969696;
$font-gray-transparent: #96969620;
$font-gray-transparent-50: #96969650;
$font-gray-transparent-70: #96969670;
$font-gray-dark: #4d4d4f;
$font-gray-light: #d4d4d4;

$principal-blue: #2A58AD; //#01305c;
$principal-blue-transparent: #2A58AD75;
$principal-lightblue: #289ccd;
$principal-lightblue-transparent: #75CCF0;
$principal-yellow: #fcc633;
$principal-yellow-transparent: #FFE8AB;
$principal-green: #11b72d;
$principal-green-transparent: #11b72d75;
$principal-red: #d00000;
$principal-red-transparent: #d0000075;
$principal-purple: #8e44ad;
$principal-orange: #ff743e;
$principal-orange-transparent: #FFC1A9;
$principal-gray-dark: $font-gray;
$principal-gray-light: #eaeaea;
$principal-gray-background: #f7f7f7;
$principal-pink: #FF819C;
$principal-brown: #56382a;

$body_bg: #f2f4f8;
$cyan: #29ffc6;
$black: #333;
$concrate: rgb(61, 61, 61);

$blue_mh: #264453;

$tooltip-background: #474747;

$nav-principal-color: $blue_mh;
$nav-side-width: 180px;
$nav-side-width-collapsed: 70px;
$nav-font-color: white;
$nav-hover-color: $blue_mh;
$nav-icon-color: #c1c3c9;
$nav-status-bg: #213c4a;

$nav-bar-items: #f2f4f8;
$nav-bar-items-active: white;
$nav-bar-items-menu-hover: #35616f;
$nav-bar-items-separator : #488193;
$nav-bar-sub-items: #1f3946;
$nav-main-selected: #24A6DB;
$nav-bar-back-button-bg: #33596c;
$nav-hover-color: $principal-lightblue;

$table-header: #efefef;
$table-color: #5e5e5e;
$table-bottom: #f2f2f2;

$widget-header-background: #e2e2e2;

$semantic-positive: $principal-green;
$semantic-neutral: $principal-orange;
$semantic-negative: $principal-red;


$product_followup: #289ccd;
$product_online: #24c1da;
$product_onsite:  #406fa9;
$product_interchain: #3ec47d;
$product_support: #bbbbbb;
$product_prestay: #147CB8;
$product_desk: #a242b1;
$product_semantic: #ffbb3c;
$product_voices: #289ccd;
$admin: $principal-purple;

$public-lightblue: $product_prestay;

$nav-side-status-pms-red: #d00814;
$nav-side-status-pms-green: $product_interchain;

$very_good: $principal-green ;
$good: #9ac60c;
$regular: $principal-orange;
$bad: #f54109;
$very_bad: $principal-red;

$products: "followup", "online", "onsite", "corporative","support","prestay","desk","semantic", "configurations", "manager", "voices";
$product_colors: $product_followup, $product_online, $product_onsite,$product_interchain,$product_support,$product_prestay,$product_desk,$product_semantic,$product_onsite, $product_onsite, $product_voices;

$turquoise: #1abc9c;
$emerald: #2ecc71;
$peter-river: #3498db;
$amethyst: #9b59b6;
$wet-asphalt: #34495e;
$green-sea: #16a085;
$nephritis: #27ae60;
$belize-hole: #2980b9;
$wisteria: #8e44ad;
$midnight-blue: #2c3e50;
$sun-flower: #f1c40f;
$carrot: #e67e22;
$alizarin: #e74c3c;
$clouds: #ecf0f1;
$concrete: #95a5a6;
$orange: #f39c12;
$pumpkin: #d35400;
$pomegranate: #c0392b;
$silver: #bdc3c7;
$asbestos: #7f8c8d;

$qualifys: "very_good", "good", "regular", "bad", "very_bad";
$qualifys_colors: $very_good, $good, $regular, $bad, $very_bad;

$satisfaction_type_0 : $very_good;
$satisfaction_type_1 : $very_good;
$satisfaction_type_2 : $good;
$satisfaction_type_3 : $regular;
$satisfaction_type_4 : $bad;
$satisfaction_type_5 : $very_bad;

$promoter: $very_good;
$passive: $regular;
$detractor: $very_bad;

$nps_types : "promoter","passive","detractor";
$nps_colors : $promoter,$passive,$detractor;

$satisfaction_type: $satisfaction_type_1,$satisfaction_type_2,$satisfaction_type_3,$satisfaction_type_4,$satisfaction_type_5;

$ta_green: #00af87;

$ng-select-bg-gray: #ebebeb;
$ng-select-option-marked: #f5f5f5;


//SUVEYS
$survey-header: 60px;
$survey-footer: 100px;
$sruvey-principal-green: #247A34;
$survey-principal-green: #247A34;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #bbbbbb;// #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #768789;// #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;


$success-snackbar-bg-success: #d4edda;
$success-snackbar-text-success: #155724;

$success-snackbar-bg-error: #f8d7da;
$success-snackbar-text-error: #721c24;

$integration-container-bg: #f2f0f0;
$integration-status-label: #0b0b0b;
$integration-status-on: #1abf05;
$integration-status-off: #f2b40b;
$integration-subtitle: #121212;
$integration-button-label: #707070;

$form-input-error: #f44336;
$integration-gray: #707070;

$rate-black-widget: #151515;
$label-gray-widget: #4D4D4F;
$sublabel-gray-widget: #878787;
