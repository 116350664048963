form{
  .form-group{
    margin-bottom: 20px;
  }
  .mat-radio-button{
    .mat-radio-label-content{
      margin-right: 8px;
      text-transform: none;
    }
  }
  .validate{
    &.type_checkbox, &.type_radiobutton{
      border: 1px solid transparent;
    }
    &.type_select{
      .content_options{
        border: 1px solid transparent;
      }
    }
  }

  &.show_invalids{
    .form-control{
      &.ng-invalid{
        border-color: $principal-red;
      }
    }
    .form-group{
      &.ng-invalid{
        .form-control{
          border-color: $principal-red;
        }
      }
    }
    .alternatives{
      .validate{
        &.ng-invalid{
          &.type_checkbox, &.type_radiobutton{
            border-color: $principal-red;
          }
          &.type_select{
            .content_options{
              border-color: $principal-red;
            }
          }
        }

      }
    }

  }
  .bottom{
    text-align: center;
    padding: 10px 0;
  }
}

.general_set_form{
  .wrapp_form{
    text-align: left;
    margin-bottom: 20px;
    label{
      text-align: left;
    }
    .title{
      font-size: 20px;
      font-weight: 400;
      color: $font-black;
      @include font("Yantramanav");
    }
    &.inner{
      .title{
        background-color: $principal-lightblue;
        color: white;
        margin-bottom: 0;
        padding: 5px;
      }
      .content-wrapp{
        background-color: $principal-gray-light;
      }
    }
    .content-wrapp{
      padding: 20px;
    }
  }


  .form-group{
    position: relative;
    margin-bottom: 20px;
  }
  small{
    position: absolute;
    left: 0;
    bottom: -20px;
    display: block;
    padding: 0 3px;
    left: 16px;
  }
  .submit{
    margin: 0 auto;
    @include button_classic($principal-orange, 14px);
  }
  .submit_form{
    padding-top: 20px;
    text-align: center;
  }
}

.form-switch{
  &.mat-slide-toggle{
    display: block;
    padding: 5px 0;
    max-width: 100px;
    &.mat-checked{
      &:not(.mat-disabled){
        .mat-slide-toggle-thumb{
          background-color: $principal-blue;
        }
        .mat-slide-toggle-bar{
          background-color: $principal-lightblue;
        }
      }
    }

  }
}

.form-list-options{
  .mat-form-field{
    .mat-form-field-infix{
      padding-top: 5px;
      margin-top: 0;
      border-top: 0;
    }


  }

}

.mat-form-field{
  &.error-float{
    position: relative;
    .alert_form{
      margin-top: 0;
    }
  }
}

.mat-checkbox{
  margin: 0 5px;
  .mat-checkbox-inner-container{
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  .mat-checkbox-label{
    font-size: 13px;
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-background{
    background-color: $principal-lightblue;
  }
}
.mat-form-field{
  &.line-off{
    .mat-form-field-underline{
      display: none;
    }
  }
  &.mat-focused{
    .mat-select{
      &.button{
        background-color: $principal-lightblue;
        @include transition(all, 0.3s);
        .mat-select-value, .mat-select-arrow{
          color: white
        }
      }
    }
  }
}
.mat-select{
  &.button{
    background-color: white;
    padding: 15px;
    border-radius: 50px;
    @include box-general-shadow();
    @include transition(all, 0.3s);
    .mat-select-value, .mat-select-arrow{
      color: $font-black;
      font-size: 18px;
    }
  }
}

.mat-select-panel{
  &.bottom_button{
    margin-top: 50px;
  }
}


.mat-slide-toggle{
  &.block{
    .mat-slide-toggle-bar{
      margin: 0 auto;
    }
    .mat-slide-toggle-label{
      display: block;
    }
  }
}



