@import '../base_ps';

.prestay-theme{
  background-color: $body_bg;
  position: relative;
  .container{
    max-width: 1366px;
    position: relative;

    @media (max-width: $media_lg){
      max-width: 1200px;
    }
    @media (max-width: $media_md){
      max-width: 1170px;
    }
    @media (max-width: $media_sm){
      max-width: 600px;
    }
  }




  @include font("Rubik");
  a, &.active{
    @include link($blue_mh,$ps_lightblue);
  }
  header{
    $space: 15px;
    height: $header_height;
    text-align: right;
    padding: $space 0;
    background-color: $body_bg;
    .language{
      position: absolute;
      top: -10px;
      right: 0;
    }
    .content_header{
      position: relative;
      .logo_area{
        text-align: left;
        .logo{
          height: $header_height - $space*2;
          display: inline-block;
        }
      }
    }
    .menu, .content_countdown{
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }
    .menu{
      margin-top: 1px;
      .item{
        display: inline-block;
        padding: 0 0;
        font-weight: bold;
        color: $blue_mh;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0 0;
        a{
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;

          &.active{
            color: $ps_lightblue;
            border-color: $ps_lightblue;
            &:hover{
              color: darken($ps_lightblue,7%);
              border-color: darken($ps_lightblue,7%);
            }
          }
        }
      }
    }

    .bell_button{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: none;
      .btn{
        min-width: auto;
        font-size: 20px;
        padding: 5px 10px;
        color: $ps_lightblue;
        background: transparent;
        border-radius: 100px;
        position: relative;
        @include box-general-shadow();
      }
      .counter{
        position: absolute;
        width: 17px;
        height: 17px;
        font-size: 12px;
        top: 10px;
        left: -11px;
        color: white;
        border-radius: 100px;
        background-color: $principal-red;
      }
    }
    @media (max-width: $media_sm){
      .bell_button{
        display: block;
      }
      .content_countdown,.language{
        display: none;
      }
      .menu{
        .item{
          font-size: 16px;
        }
      }
    }
  }



  #counter_mobile{
    display: none;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
    padding: 20px 0;
    background-color: white;
    .countdown_box{
      margin-left: 0;
      padding-right: 5px;
    }
    .send{
      position: absolute;
      left: 10px;
      bottom: 18px;
      padding: 3px 7px;
      font-size: 14px;
      display: none;
    }
    .inline{
      padding: 0 2px;
    }
    .inline, .count-down, .left_day{
      font-size: 16px;
    }
    @media (max-width: $media_sm){
      display: block;
    }

    &.has_products{
      .countdown_box{
        text-align: right;
        @include transition(all,0.2s);
      }
      .send{
        display: block;
      }
    }

  }


  .countdown_box{
    margin-left: 20px;
    .inline{
      padding: 0 5px;
      color: $blue_mh;
      font-weight: bold;
      vertical-align: middle;
      font-size: 20px;
      font-weight: normal;
    }

    .count-down, .left_day{
      color: $ps_pink;
      font-weight: bold;
      font-size: 20px;
      font-weight: normal;
      @include font("Rubik");
    }

  }

  .counter{
    font-size: 20px;
  }

  .main_content{
    padding-top: ($header_height + 20);
    overflow-y: scroll;
    @media (max-width: $media_sm){
      padding-bottom: 50px;
    }
  }

  .btn{
    min-width: 100px;
  }

  .mat-sidenav{
    max-width: 80%;
    width: 80%;
  }

  #mobile_menu{
    padding: 10px;
  }
}

.btn-ps-blue{
  @include button_classic($ps_lightblue,16px);
  padding: 5px 15px;
  &:hover{
    background-color: white;
    border-color: $ps_lightblue;
    color: $ps_lightblue;
  }
}

.btn-ps-white{
  @include button_classic(white,16px,$ps_lightblue);
  padding: 5px 15px;
  border-color: $ps_lightblue;
  &:visited, &:active{
    color: $ps_lightblue!important;
  }
  &:hover{
    background-color: $ps_lightblue;
    border-color: white;
    color: white!important;
  }
}

.btn-ps-green{
  @include button_classic($ps_green,16px);
  padding: 5px 15px;
  &:hover{
    background-color: white;
    border-color: $ps_green;
    color: $ps_green;
  }
}

.btn-ps-yellow{
  @include button_classic($ps_yellow,16px);
  padding: 5px 15px;

}


.btn-ps-added{
  @include button_classic($ps_green,16px);
  padding: 5px 15px;
  &:hover, &.mobile{
    background-color: white;
    border-color: $principal-red;
    color: $principal-red;
    i:before{
      content: "\f2ed";
    }
  }
}

.modal-ps{
  background-color: rgba($ps_blue, 0.7);
  .modal-content{
    background-color: transparent;
    border: none;
    .modal-header{
      border: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 10;
      background-color: transparent;
      .close{
        color: $principal-red;
        opacity: 1;
        border-radius: 100px;
        padding: 5px 8px;
        font-size: 16px;
        margin-right: 5px;
        background-color: white;
        @include box-general-shadow();
      }
    }
    .modal-body{
      background-color: white;
      padding: 0;
      margin-top: 12px;
      border: none;
      .btn{
        min-width: 100px;
      }
    }
    .modal-footer{
      padding: 15px;
    }
    .modal-content-padding{
      padding: 20px;
      padding-top: 30px;
    }
  }
  .general_set_form{
    small{
      left: 15px;
    }
  }
}

.modal-ps-alerts{
  @include font-elements('Rubik');

  max-width: none;
  text-align: center;

  .modal-header{
    position: relative;
    text-align: center;
    display: block;
    padding-top: 45px;
    font-size: 22px;
    font-weight: 500;
    color: white;
    border-radius: 0;
    .content_icon{
      position: absolute;
      width: 100%;
      left: 0;
      top: -80px;
      text-align: center;
      .icon{
        color: white;
        font-size: 50px;
        padding: 2px 18px;
        border-radius: 100px;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
      &.animate{
        top: -45px;
        @include transition(all 0.3s);
      }
    }
  }
  .modal-content{
    border-radius: 0;
    border: 0;
    border-top: 3px solid transparent;
    margin-top: 80px;
  }
  .modal-footer{
    border: 0;
    padding: 0;
    padding-bottom: 15px;
    padding-right: 15px;
  }
  .modal-body{
    text-align: center;
    font-size: 18px;
    color: $blue_mh;
  }
  &.success{
    .icon,.modal-header{
      background-color: $ps_green;
    }
    .icon{
      box-shadow: 0 0 3px darken($ps_green, 5%);
    }
  }
  &.error{
    .icon,.modal-header{
      background-color: $principal-red;
    }
    .icon{
      box-shadow: 0 0 3px darken($principal-red, 5%);
    }
  }

}

footer{
  text-align: center;
  color: $black;
  padding: 40px 0;
  margin-top: 60px;
  .logo{
    width: 100px;
  }
  .hotel_name{
    font-size: 30px;
    margin: 10px 0;
    .enphasis{
      font-size: 14px;
    }
  }
  .info{
    color: $font-gray;
  }
  @media (max-width: $media_sm){
    margin-top: 20px;
  }
}

