$header_height: 120px;
$container_padding: 50px;
$body_bg: white;
$ps_pink: #fb7878;
$ps_green:#23c362;
$ps_lightblue: #289ccd;
$ps_blue: #224452;
$ps_yellow: #E2C400;

@mixin ps_title($color:$blue_mh, $size:28px){
  font-size: $size;
  color: $color;
  text-align: center;
  font-weight: 500;
  @include font("Rubik");
}

@mixin ps_subtitle($color:$font-gray, $size:16px){
  font-size: $size;
  color: $color;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
  @include font("Rubik");
}
