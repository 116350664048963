.button-small{
  padding: 4px 10px;
  font-size: 12px;
}
.btn{
  vertical-align: middle;
  margin: 0;
  cursor: pointer;
  i, span{
    vertical-align: middle;
  }
  &.icon-left, &-icon-left{
    i,.mat-icon{
      margin-right: 5px;
    }
  }
  &.icon-right, &-icon-right{
    i, .mat-icon{
      margin-left: 5px;
    }
  }
  &-link{
    padding: 0;
    @include link($principal-lightblue);
  }
  &-clean, &-clear{
    padding: 0;
    &:focus{
      box-shadow: 0 0 0 3px $principal-gray-light;
    }
  }
  &-white{
    @include button_classic(white, 14px, $font-black);
    &:hover{
      background-color: $principal-lightblue;
      color: white;
    }
  }
  &-send{
    max-width: 200px;
  }
  &-small{
    padding: 3px 10px;
    font-size: 12px;
    @extend .button-small;
  }
  &:focus{
    box-shadow: none;
  }
}



.btn-orange{
  @include button_classic($principal-orange, 14px);
  &:disabled{
    background-color: $font-gray;
    border-color: $font-gray;
    opacity: 1;
    cursor: unset;
    &:hover{
      color: white;
      text-decoration: none;
    }
  }
}

.btn-orange-square {
  color: white;
  background-color: $principal-orange;
  border-color: $principal-orange;
  &:hover{
    color: white;
  }
}

.btn-black{
  @include button_classic($font-black, 14px);
}
.btn-blue{
  @include button_classic($blue_mh, 14px);
}
a.btn-orange {
  color: white;
  &:hover{
    color: white;
  }
}
.btn-white{
  background-color: white;
  box-shadow: 2px 2px 4.4px #00000026;
  color: $blue_mh;
  padding: 10px 20px;
}

.btn-danger {
  background-color: $bad;
}

.buttons-controls{
  height: 60px;
  position: relative;
  .btn-move{
    top: 5px;
    position: absolute;
  }
  .btn-prev{
    left: 10px;
  }
  .btn-next{
    right: 10px;
  }
}
.btn-move{
  width: 40px;
  height: 40px;
  background-color: white;
  color: $font-gray;
  text-align: center;
  border-radius: 100px;
  font-size: 25px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  @include box-general-shadow();
}

.btn-cleaner {
  @extend .btn;

  margin-left: 10px;
  padding: 10px 30px;
  background-color: white;
  color: $principal-green;
  font-weight: bold;
  border-radius: 30px;
  font-size: 16px;
  border: 2px solid white;
  box-shadow: 0 0 5px $principal-gray-light;
  cursor: pointer;
  @include transition(border 0.2s);
  &:hover, &:focus {
    outline: none;
    border: 2px solid $principal-green !important;
    @include transition(border 0.2s);
  }
}

.btn-filter {
  @extend .btn-white;

  color: $blue_mh;
  background-color: white;
  min-width: 200px;
  i {
    color: $principal-lightblue;
    margin-right: 10px;
  }
  &:hover {
    border: 2px solid $principal-lightblue;
    background-color: white;
    color: $blue-mh;
  }
  span {
    font-size: 16px;
    font-weight: initial;
    margin-left: 2%;
  }
}

button.btn-rounded {
  border-radius: 30px;
}

.btn-stroked {
  border: 1px solid $gray-400;
  padding: 5px 15px;
  border-radius: 5px;
  &:hover {
    background: $font-gray-transparent;
  }
}
