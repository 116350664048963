@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin box-general($radius:7px,$color:darken($principal-gray-light,5%)){
  background-color: white;
  box-shadow: 0 0 5px $color;
  border-radius: $radius;
}
@mixin box-general-shadow($color: $font-gray) {
  $color-dark: darken($color, 15%);
  box-shadow: 0 2px 1px -1px rgba($color-dark,.2), 0 1px 1px 0 rgba($color-dark,.14), 0 1px 3px 0 rgba($color-dark,.12);
}

@mixin no-wrap{
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@mixin shadow(){
  box-shadow: 0 0 2px darken($body_bg, 15%);
}

@mixin arrow($direction:'up', $size:5px, $color:red){
  @if $direction == "up" {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
  @if $direction == "down" {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }
  @if $direction == "right" {
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }
  @if $direction == "left" {
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right:$size solid $color;
  }
}

@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property:    $property;
  -o-transition-property:      $property;
  -webkit-transition-property: $property;
  transition-property:         $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property:    $duration;
  -o-transition-property:      $duration;
  -webkit-transition-property: $duration;
  transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

@mixin linear-gradient($from, $to) {
  background: $to;
  background: -moz-linear-gradient(top, $from 0%, $to 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
  background: -webkit-linear-gradient(top, $from 0%,$to 100%);
  background: -o-linear-gradient(top, $from 0%,$to 100%);
  background: linear-gradient(to bottom, $from 0%,$to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})
}

@mixin horizontal-gradient($from, $to, $stop: 100%) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);

  /* IE10+ */
  background: -ms-linear-gradient(left, $from 0%, $to $stop);

  /* Standard */
  background: linear-gradient(to right, $from 0%, $to $stop);

  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}
@mixin link($pincipal:$font-gray-dark, $hover:$principal-orange, $decorate: false){
  color: $pincipal;
  cursor: pointer;
  &:active, &:visited{
    // color: $pincipal !important;
  }
  @include transition(color 0.2s);
  &:hover:not(.selected){
    color: darken($hover, 10%) !important;
    text-decoration: none;
    @include transition(color 0.2s);
  }

  @if $decorate {
    &::after{
      content:' ';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      background-color: transparent;
      left: 0;
    }
    &.selected, &:hover{
      &::after{
        background-color: $principal-orange;
        bottom: 5px;
        @include transition(all, 0.2s);
      }

    }
  }
}

@mixin font($principal){
  font-family: $principal, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}

@mixin font-elements($principal) {
  div,label,h2,h3,h4,h5,span{
    @include font($principal);
  }
}

@mixin button_classic($color: $principal-orange, $font-size: 20px, $color_text: white){
  padding: 10px 30px;
  background-color: $color;
  color: $color_text;
  font-weight: bold;
  border-radius: 30px;
  font-size: $font-size;
  border: 2px solid $color;
  box-shadow: 0 0 5px $principal-gray-light;
  cursor: pointer;
  @include transition(background-color 0.2s);
  &:hover{
    background-color: darken($color, 10%);
    color: $color_text;
    @include transition(background-color 0.2s);
  }
  &:focus{
    outline: none;
    color: $color_text;
    border-color: darken($color, 10%);
  }
  &.small, &.btn-small{
    padding: 3px 10px;
    font-size: 12px;
  }
}

@mixin nps_class($selector){
  $i: 1;
  @each $item in $nps_types {
    $color: nth($nps_colors, $i);
    &.#{$selector}_#{$item} {
      background-color: $color;
    }
    $i: $i + 1;
  }
}

@mixin not_paid($perc:60%){
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 7px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+61&0+0,1+60 */
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) $perc, rgba(255,255,255,1) calc( #{$perc} + 1% )); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) $perc,rgba(255,255,255,1) calc( #{$perc} + 1% )); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) $perc,rgba(255,255,255,1) calc( #{$perc} + 1% )); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

@mixin arrow_tag($background-color: $principal-lightblue, $color: white, $color-behind: white) {
  padding-right: 5px;
  display: inline-block;
  background-color: $background-color;
  color: $color;

  &:before {
    background-color: $color-behind;
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 13px 15px 13px 0;
    display: inline-block;
    vertical-align: middle;
    border-color: $color-behind $background-color $color-behind $color-behind;
  }
}
